/* Complete.css */

.complete-page{
  width: 100%;
  padding: 40px;
}
  
.table-container {
  margin: 20px auto;
  /* margin: auto; */
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.table-responsive {
  overflow-x: auto;
  border-radius: 8px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: middle;
  text-align: center;
}

.table thead th {
  background-color: #f8f9fa;
  color: #333333;
  font-weight: bold;
  text-transform: uppercase;
}

.table tbody tr:hover {
  background-color: #f1f3f5;
}

.table th:first-child,
.table td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table th:last-child,
.table td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.status {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.25rem;
  font-size: 0.875em;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.status.complete {
  color: #28a745;
}

.status.terminate {
  color: #dc3545;
}

.status.quotafull {
  color: #ffc107;
}

