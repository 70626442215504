.contact-us-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 2em; /* Default font-size */
}

.form-group {
  text-align: left;
  margin-bottom: 20px;
  flex: 1;
  /* Added margin-bottom to ensure consistent spacing */
}

.form-group label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333333;
  font-size: 1em; /* Default font-size */
}

.form-group input {
  flex: 1;
  width: 98%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  /* Increased border-radius for better rounding */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* Added box-shadow for a subtle elevation effect */
  font-size: 1em;
  color: #555555;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Added transition for box-shadow */
}

.form-group textarea {
  flex: 1;
  width: 99%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  /* Increased border-radius for better rounding */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* Added box-shadow for a subtle elevation effect */
  font-size: 1em;
  color: #555555;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  /* Added transition for box-shadow */
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.25);
  /* Enhanced box-shadow on focus for better emphasis */
}

.submit-btn {
  display: block;
  width: 25%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  /* Increased border-radius for better rounding */
  background-color: #007bff;
  color: #ffffff;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  /* Added transition for box-shadow */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  /* Added box-shadow for a subtle elevation effect */
}

.submit-btn:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  /* Enhanced box-shadow on hover for better emphasis */
}

.name,
.email_phone {
  display: flex;
  /* gap: 20px; Ensure there is a consistent gap between input fields */
  margin-bottom: 20px;
}

.terms {
  text-align: left;
  margin: 0 0 25px 0;
}

/* Responsive font-sizes */
@media (max-width: 1200px) {
  .contact-form h2 {
    font-size: 1.8em;
  }

  .form-group label,
  .form-group input,
  .form-group textarea,
  .submit-btn {
    font-size: 0.95em;
  }
}

@media (max-width: 900px) {
  .contact-form h2 {
    font-size: 1.6em;
  }

  .form-group label,
  .form-group input,
  .form-group textarea,
  .submit-btn {
    font-size: 0.9em;
  }
}

@media (max-width: 600px) {
  .contact-form h2 {
    font-size: 1.4em;
  }

  .form-group label,
  .form-group input,
  .form-group textarea,
  .submit-btn {
    font-size: 0.85em;
  }

  .name,
  .email_phone {
    flex-direction: column;
  }

  .submit-btn {
    padding: 15px;
    width: 100%;
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .contact-form h2 {
    font-size: 1.2em;
  }

  .form-group label,
  .form-group input,
  .form-group textarea,
  .submit-btn {
    font-size: 0.8em;
  }
}
