/* Dashboard.css */

.container-fluid {
  padding: 40px;
  
}

.form-horizontal {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
}

.form-group {
  flex: 1;
  margin-right: 10px;
}

input[type="text"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  height: 100%;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.table-container {
  margin: 20px auto;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.table-responsive {
  overflow-x: auto;
  border-radius: 8px;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: middle;
  text-align: center;
}

.table thead th {
  background-color: #f8f9fa;
  color: #333333;
  font-weight: bold;
  text-transform: uppercase;
}

.table tbody tr:hover {
  background-color: #f1f3f5;
}

.table th:first-child,
.table td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table th:last-child,
.table td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.status {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.25rem;
  font-size: 0.875em;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.status.complete {
  color: #28a745;
}

.status.terminate {
  color: #dc3545;
}

.status.quotafull {
  color: #ffc107;
}

input[type="date"] {
  width: 98.2% !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-export {
  width: 10%;
  color: #ffffff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  margin: 0 0 0 20px;
}

.btn-export:hover {
  color: #ffffff;
  background-color: #138496;
  border-color: #117a8b;
}

.total-text {
  display: block;
  margin: 20px 0;
  font-weight: bold;
  color: #333333;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 10px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #0056b3;
}

.pagination span {
  margin: 0 10px;
  font-size: 16px;
}

.parent_div {
  max-width: 26.0rem;
  margin: 5.0rem auto;
  padding: 2.5rem 1.5rem;
  background-color: #fff;
  border-radius: 2.0rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.parent {
  background: hsla(197, 100%, 63%, 1);

background: linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(197, 100%, 63%, 1) 0%, hsla(294, 100%, 55%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 );
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#text,
#password {
  width: calc(100% - 2.0rem);
  padding: 1rem;
  margin: 1.0rem 0;
  border: 1px solid #ccc;
  border-radius: 2.0rem;
  outline: none;
  font-size: 1rem;
}

#login-btn {
  width: calc(100% - 2.0rem);
  padding: 1rem;
  margin: 1.0rem 0;
  border: 1px solid #ccc;
  border-radius: 2.0rem;
  outline: none;
  font-size: 1.2rem;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.parent_div h2 {
  text-align: center;
  margin: 0.5rem 0 1.0rem 0;
  font-size: 2rem;
}

.gradient-text {
  font-size: 2em; /* Increase the text size */
  font-weight: bold;
  background: linear-gradient(to right, darkblue, violet);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .form-group {
    margin-right: 5px;
  }

  .btn-export {
    width: 15%;
  }
}

@media (max-width: 992px) {
  .container-fluid {
    padding: 20px;
  }

  .form-group {
    flex: 1 1 45%;
    margin-right: 5px;
  }

  .btn-export {
    width: 20%;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding: 15px;
  }

  .form-horizontal {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    flex: 1 1 100%;
    margin-right: 0;
  }

  .btn-primary{
    width: 100%;
  }

  .pages {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination span {
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .container-fluid {
    padding: 10px;
  }

  .form-group {
    flex: 1 1 100%;
  }

  .btn {
    margin-bottom: 10px;
  }

  .total-text {
    text-align: center;
    margin: 10px 0;
  }

}